.customer-solution-content {
  width: 100%;
  .customer-solution-item-box {
    width: 100%;
    max-width: 1360px;
    height: 600px;
    margin: 0 auto;
    position: relative;
    padding-top: 149px;
    .customer-solution-item-title {
      height: 56px;
      font-size: 40px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(12, 12, 12, 1);
      line-height: 56px;
      span {
        display: block;
        width: 40px;
        height: 3px;
        background: rgba(77, 77, 77, 1);
        margin-top: 12px;
      }
    }
    .customer-solution-item-describe {
      width: 460px;
      height: 200px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(14, 14, 14, 1);
      line-height: 40px;
      margin-top: 46px;
    }
  }
}
.positionLeft {
  position: absolute;
  bottom: 0;
  left: 0;
}
.positionRight {
  position: absolute;
  bottom: 0;
  right: 0;
}
.solution-column3Positon {
  bottom: -67px;
}

@media screen and (max-width: 767px) {
  .customer-solution-content {
    .customer-solution-item-box {
      padding-top: 20px;
      .customer-solution-item-title {
        font-size: 18px;
        line-height: 40px;
      }
      .customer-solution-item-describe {
        width: 100%;
        font-size: 14px;
        line-height: 30px;
        margin-top: 20px;
      }
    }
  }
  .onTrial-button-box {
    flex-direction: column;
    width: 100%;
    .onTrial-button {
      width: 100px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
    }
    .onTrial-tell {
      width: 240px;
      font-size: 26px;
      margin: 0;
    }
  }
  .positionLeft,
  .positionRight {
    width: 80%;
    padding: 0;
  }
}
