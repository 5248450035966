.park-title_bj {
  width: 100%;

  .park-top-box {
    width: 100%;
    height: 640px;
    position: relative;
    margin-top: 80px;

    .solve-bj {
      width: 100%;
      height: 100%;
      vertical-align: top;
      border: none;
    }
    .park-top-title {
      width: 600px;
      height: 85px;
      font-size: 60px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
      line-height: 84px;
      position: absolute;
      top: 181px;
      left: 50%;
      margin-left: -300px;
    }
    .top-text {
      margin-left: -370px;
      position: absolute;
      top: 290px;
      left: 50%;
      width: 740px;
      text-align: center;
      height: 100px;
      font-size: 36px;
      font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
      font-weight: 300;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
    }
  }
}
.park-box2 {
  width: 100%;
  height: 1160px;
  .park-box2-main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 80px 0;

    .park-box2-item {
      height: 476px;
      .park-box2-item-icon {
        display: block;
        width: 148px;
        height: 148px;
        background: rgba(61, 133, 255, 1);
        border-radius: 50%;
        color: #fff;
        font-size: 72px;
        line-height: 148px;
        margin: 0 auto;
      }
      .park-box2-item-title {
        width: 241px;
        height: 56px;
        font-size: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(12, 12, 12, 1);
        line-height: 56px;
        margin: 26px auto;

        span {
          display: block;
          width: 40px;
          height: 3px;
          background: rgba(77, 77, 77, 1);
          margin: 12px auto 0;
        }
      }
      .park-box2-item-describe {
        width: 460px;
        height: 200px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(14, 14, 14, 1);
        line-height: 40px;
        text-align: left;
        margin: 46px auto 0;
      }
    }
  }
}
.park-box4 {
  height: 535px;
  width: 100%;
  .park-box4-main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .park-box4-item {
      height: 283px;
      margin-top: 85px;
      text-align: center;
      .park-box4-item-title {
        height: 100px;
        font-size: 72px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(101, 101, 101, 1);
        line-height: 100px;
        display: block;
        margin: 33px auto 0;
      }
    }
  }
}
.park-box5 {
  height: 1167px;
  width: 100%;
  padding-top: 101px;
  .park-box5-title {
    width: 360px;
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(12, 12, 12, 1);
    line-height: 56px;
    margin: 0 auto;
  }
  img {
    display: block;
    margin: 93px auto 0;
  }
}
.mrgTop60 {
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .park-title_bj {
    overflow: hidden;
    .park-top-box {
      .park-top-title {
        width: 80%;
        font-size: 30px;
        margin-left: -150px;
      }
      .top-text {
        width: 80%;
        font-size: 18px;
        margin-left: -150px;
      }
    }
  }
  .park-box2 {
    .park-box2-main {
      .park-box2-item {
        .park-box2-item-icon {
          width: 80px;
          height: 80px;
          font-size: 40px;
          line-height: 80px;
        }
        .park-box2-item-title {
          font-size: 30px;
        }
        .park-box2-item-describe {
          width: 100%;
          padding: 0 10px;
        }
      }
    }
  }
  .park-box3 {
    display: block;
    width: 100%;
  }
  .park-box4 {
    height: 980px;
    .park-box4-main {
      .park-box4-item {
        height: 230px;
        margin-top: 10px;
        .park-box4-item-title {
          font-size: 30px;
        }
      }
    }
  }
  .park-box5 {
    height: 400px;
    padding-top: 20px;
    .park-box5-title {
      font-size: 20px;
      text-align: center;
    }
    img {
      width: 100%;
      margin: 20px auto 0;
    }
  }
  .mrgTop60 {
    margin-top: 10px;
  }
}
