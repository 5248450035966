// @font-face {
//   font-family: PingFang;
//   src: url("../../../static/PingFang\ SC\ Regular.ttf");
// }
.customer-container {
  width: 100%;
  height: 61vh;
  .customer-content {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    padding: 64px 24px;
    .customer-title {
      display: block;
      font-size: 40px;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      color: #0c0c0c;
      margin: 0 auto;
      text-align: center;
    }
  }
}

.customer-item-box {
  margin: 10px 0;
  opacity: 1;
  transform: translate(0px, 0px);

  .customer-item {
    padding: 20px;
    .customer-img {
      display: block;
      width: 100%;
      height: 80px;
    }
  }
}

// .Tenants-container {
//   border-top: 1px solid #979797;
//   border-bottom: 1px solid #979797;
//   width: 1200px;
//   height: 287px;
//   margin: 50px auto 0;
//   padding: 15px 0;
//   p {
//     width: 1176px;
//     height: 50px;
//     font-size: 24px;
//     font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
//     font-weight: normal;
//     color: rgba(99, 99, 99, 1);
//     line-height: 50px;
//     margin: 0 auto;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }
// }
.Tenants-container {
  width: 1200px;
  height: 200px;
  margin: 20px auto;
  #box {
    height: 200px;
    margin: 0 auto;
    overflow: hidden;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow: hidden;
  }
  ul li {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    max-width: 100%;
  }
  a {
    text-decoration: none;
    color: rgba(99, 99, 99, 1);
    font-size: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 767px) {
  .customer-container {
    height: 130vh;
  }
  .Tenants-container {
    width: 100%;
    p {
      width: 100%;
    }
    a {
      font-size: 14px;
    }
  }
  .customer-container .customer-content {
    padding: 24px;
  }
}
