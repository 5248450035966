.solution-bg {
  width: 100%;
  height: 360px;
  padding-top: 100px;
  text-align: center;

  img {
    display: inline-block;
  }
  span {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .solution-bg {
    padding-top: 10px;
    img {
      width: 50%;
    }
  }
}
