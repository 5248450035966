@import "./global";

.content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  position: absolute !important;
  width: 100%;
}

.video {
  max-width: 800px;
}

.templates-wrapper {
  user-select: none;
}

.is-edit {
  * {
    pointer-events: none;
  }
}

#react-content {
  min-height: 100%;
}

.bgF9 {
  background-color: #f9f9f9 !important;
}
.height100 {
  height: 100%;
}
.paddingLeft100 {
  padding-left: 100px;
}
.paddingRight100 {
  padding-right: 100px;
}
@media screen and (max-width: 767px) {
  .paddingLeft100 {
    padding: 0 20px;
  }
  .paddingRight100 {
    padding: 0 20px;
  }
  .height100 {
    height: 40%;
    margin: 5% 0;
  }
}
