@import "./custom.less";

@content2: content2;
.@{content2} {
  > h1,
  > p {
    text-align: center;
    position: relative;
    top: 15%;
  }
  &-wrapper {
    height: 1281px;
  }
  &-contentWrapper {
    position: relative;
    top: 20%;
    height: 60%;
    ul {
      > li {
        display: inline-block;
        width: 33.33%;
        padding: 6% 5% 0;
        vertical-align: top;
        text-align: center;
        .img {
          display: inline-block;
          width: 200px;
          height: 200px;
          vertical-align: top;
        }
        .text {
          width: 85%;
          display: inline-block;
          h1 {
            height: 30px;
            font-size: 30px;
            font-family: NotoSansHans-Medium, NotoSansHans;
            font-weight: 500;
            color: #000000;
            line-height: 30px;
            letter-spacing: 2px;
            margin: 48px auto 0;
          }
          p {
            width: 370px;
            height: 90px;
            font-size: 18px;
            font-family: NotoSansHans-Medium, NotoSansHans;
            font-weight: 500;
            color: rgba(0, 0, 0, 1);
            line-height: 30px;
            text-align: left;
            margin: 10px auto 0;
          }
        }
      }
    }
  }
}
.Fsolution-corevalue-box {
  padding-top: 80px;
  .corevalue-title {
    width: 160px;
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(12, 12, 12, 1);
    line-height: 56px;
    margin: 0 auto;
  }
  .corevalue-title-describe {
    height: 40px;
    width: 200px;
    font-size: 24px;
    font-family: NotoSansHans-Medium, NotoSansHans;
    font-weight: 500;
    color: rgba(14, 14, 14, 1);
    line-height: 40px;
    margin: 2px auto 0;
  }
}
@media screen and (max-width: 767px) {
  .@{content2}-wrapper {
    height: 1750px;
    .@{content2} {
      overflow: hidden;
      width: 90%;
      margin: auto;
      > h1,
      > p {
        position: relative;
        top: auto;
      }
      > h1 {
        margin: 40px auto 20px;
        font-size: 24px;
      }
      &-contentWrapper {
        top: auto;
        margin: 20px auto;
        height: auto;
        ul {
          > li {
            position: relative;
            width: 90%;
            margin: auto;
            display: block;
            .img {
              width: 100px;
              height: 100px;
            }
            .text {
              width: 90%;
              h1 {
                margin: 10px auto 0;
              }
              p {
                width: 90%;
                font-size: 14px;
              }
            }
            h1 {
              font-size: 20px;
            }
            &.queue-anim-leaving {
              position: relative !important;
            }
          }
        }
      }
    }
  }
  .Fsolution-corevalue-box {
    padding-top: 20px;
    .corevalue-title {
      font-size: 30px;
      text-align: center;
    }
    .corevalue-title-describe {
      font-size: 18px;
      text-align: center;
    }
  }
}
