// common样式
.bgFA {
  background-color: #fafafa;
}
.fLeft {
  float: left;
}
.fRight {
  float: right;
}
//
.home-video-box {
  width: 100%;
  background-color: #5c5d67;
  height: 540px;
  margin-top: 80px;
  color: #fff;
  text-align: center;
  position: relative;
  .home-vidoe-h1 {
    position: absolute;
    font-size: 60px;
    line-height: 84px;
    height: 85px;
    width: 367px;
    font-family: PingFang;
    position: absolute;
    top: 134px;
    left: 50%;
    margin-left: -183px;
  }
  .home-vidoe-h2 {
    width: 311px;
    height: 50px;
    font-size: 36px;
    font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
    font-weight: normal;
    color: rgba(255, 255, 255, 1);
    line-height: 50px;
    position: absolute;
    top: 225px;
    left: 50%;
    margin-left: -155px;
  }
  .home-video-button {
    display: block;
    width: 44px;
    height: 44px;
    position: absolute;
    top: 335px;
    left: 50%;
    margin-left: -22px;
  }
  .home-video-describe {
    width: 85px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 20px;
    position: absolute;
    top: 387px;
    left: 50%;
    margin-left: -42px;
  }
}
.home-main-container {
  width: 100%;
}
.home-product-describe-box {
  height: 520px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 73px 0;
  .home-product-img {
    width: 592px;
    height: 374px;
  }
  .home-product-title {
    height: 56px;
    font-size: 40px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(12, 12, 12, 1);
    line-height: 56px;
    text-align: center;
    span {
      display: block;
      width: 40px;
      height: 3px;
      background: rgba(77, 77, 77, 1);
      margin: 12px auto;
    }
  }
  .home-product-describe {
    width: 524px;
    height: 160px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(14, 14, 14, 1);
    line-height: 40px;
    margin-top: 31px;
  }
}
.onTrial-button-box {
  height: 154px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  .onTrial-button {
    display: inline-block;
    width: 154px;
    height: 54px;
    background: rgba(61, 133, 255, 1);
    border-radius: 100px;
    color: #fff;
    font-size: 24px;
    line-height: 54px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    text-align: center;
  }
  .onTrial-tell {
    display: inline-block;
    width: 263px;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(12, 12, 12, 1);
    line-height: 50px;
    margin-left: 18px;
  }
}

@media screen and (max-width: 767px) {
  .home-product-describe-box {
    padding: 10px 0;
    .home-product-img {
      width: 100%;
      height: 240px;
    }
    .home-product-title {
      font-size: 18px;
    }
    .home-product-describe {
      width: 100%;
      font-size: 14px;
    }
  }
  .onTrial-button-box {
    flex-direction: column;
    .onTrial-button {
      width: 100px;
      height: 35px;
      line-height: 35px;
      font-size: 14px;
    }
    .onTrial-tell {
      width: 240px;
      font-size: 26px;
      margin: 0;
    }
  }
}
