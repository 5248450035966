@content: content-template;
.@{content}-wrapper {
  width: 100%;
  background: #fff;
  height: 100vh;
  border-color: #666;
  position: relative;
  .@{content} {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: auto;
    position: relative;
    h1 {
      font-size: 32px;
      font-weight: normal;
      color: #404040;
      line-height: 48px;
    }
    > p {
      font-size: 12px;
      margin: 20px auto;
    }
  }
}

.content-half-wrapper {
  height: 50vh;
}
.title_bj {
  width: 100%;

  .forwarder-top-box {
    width: 100%;
    height: 640px;
    position: relative;
    margin-top: 80px;

    .solve-bj {
      width: 100%;
      height: 100%;
      vertical-align: top;
      border: none;
    }
    .forwarder-top-title {
      width: 421px;
      height: 85px;
      font-size: 60px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
      line-height: 84px;
      position: absolute;
      top: 181px;
      left: 50%;
      margin-left: -210px;
    }
    .top-text {
      margin-left: -405px;
      position: absolute;
      top: 290px;
      left: 50%;
      width: 810px;
      text-align: center;
      height: 100px;
      font-size: 36px;
      font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
      font-weight: 300;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
    }
  }
}
.flower-img {
  width: 100%;
  height: 1650px;
  padding: 58px 0;
}
@media screen and (max-width: 767px) {
  .@{content}-wrapper {
    .@{content} {
      h1 {
        font-size: 24px;
      }
    }
  }
  .title_bj {
    .forwarder-top-box {
      .forwarder-top-title {
        width: 90%;
        left: 5%;
        margin-left: 0;
        text-align: center;
        font-size: 30px;
      }
      .top-text {
        width: 90%;
        left: 5%;
        margin-left: 0;
        font-size: 16px;
      }
    }
  }
  .flower-img {
    height: auto;
    padding: 20px 0;
    img {
      width: 100%;
    }
  }
}
