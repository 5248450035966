.about-main-container {
  width: 100%;
  height: 100%;

  .about-top-box {
    width: 100%;
    height: 640px;
    position: relative;
    margin-top: 80px;

    .about-top-bj {
      display: block;
      width: 100%;
      height: 100%;
    }
    .about-top-title {
      width: 1127px;
      height: 240px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 40px;
      position: absolute;
      top: 200px;
      left: 50%;
      margin-left: -563px;
    }
  }
  .about-box2 {
    height: 922px;
    width: 100%;
    padding-top: 105px;
    .about-box2-title {
      width: 160px;
      height: 56px;
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(12, 12, 12, 1);
      line-height: 56px;
      text-align: center;
      margin: 0 auto;
    }
    .about-box2-content {
      width: 696px;
      height: 40px;
      font-size: 24px;
      font-family: NotoSansHans-Medium, NotoSansHans;
      font-weight: 500;
      color: rgba(14, 14, 14, 1);
      line-height: 40px;
      margin: 10px auto;
      text-align: center;
    }
    .about-box2-main {
      width: 100%;
      max-width: 1200px;
      margin: 110px auto 0;
      text-align: center;

      .about-box2-item {
        height: 370px;
        .about-box2-item-title {
          height: 30px;
          font-size: 30px;
          font-family: NotoSansHans-Medium, NotoSansHans;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          line-height: 45px;
          letter-spacing: 2px;
          margin: 45px auto 0;
        }
        .about-box2-item-describe {
          display: block;
          height: 60px;
          font-size: 18px;
          font-family: NotoSansHans-Medium, NotoSansHans;
          font-weight: 500;
          color: rgba(0, 0, 0, 1);
          line-height: 30px;
          margin-top: 20px;
        }
      }
    }
  }
  .borderLeft {
    border-left: 1.5px solid #979797;
  }
  .about-box3 {
    width: 100%;
    position: relative;
    img {
      display: block;
      width: 100%;
    }
    .about-box3-title {
      width: 420px;
      height: 42px;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(90, 151, 255, 1);
      line-height: 42px;
      position: absolute;
      top: 74px;
      left: 60%;
    }
    .about-box3-content {
      width: 600px;
      height: 120px;
      font-size: 24px;
      font-family: NotoSansHans-Regular, NotoSansHans;
      font-weight: 400;
      color: rgba(90, 151, 255, 1);
      line-height: 40px;
      position: absolute;
      top: 125px;
      left: 60%;
    }
  }
}
@media screen and (max-width: 767px) {
  .about-main-container {
    .about-top-box {
      .about-top-title {
        width: 90%;
        font-size: 18px;
        top: 10px;
        left: 0;
        margin-left: 5%;
      }
    }
    .about-box2 {
      height: 1350px;
      padding-top: 20px;
      .about-box2-title {
        font-size: 30px;
      }
      .about-box2-content {
        width: 90%;
        height: auto;
        font-size: 14px;
      }
      .about-box2-main {
        margin: 20px auto 0;
        .about-box2-item {
          .about-box2-item-title {
            font-size: 25px;
            margin: 0 auto;
          }
        }
      }
    }
    .borderLeft {
      border: none;
    }
    .about-box3 {
      height: 230px;
      .about-box3-title {
        font-size: 18px;
        position: relative;
        top: 0;
        left: 10px;
      }
      .about-box3-content {
        font-size: 14px;
        position: relative;
        top: 0;
        left: 10px;
        line-height: 20px;
        height: 70px;
      }
    }
  }
}
