.title_bj {
  width: 100%;

  .customer-top-box {
    width: 100%;
    height: 640px;
    position: relative;
    margin-top: 80px;

    .solve-bj {
      width: 100%;
      height: 100%;
      vertical-align: top;
      border: none;
    }
    .customer-top-title {
      width: 421px;
      height: 85px;
      font-size: 60px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
      line-height: 84px;
      position: absolute;
      top: 181px;
      left: 50%;
      margin-left: -210px;
    }
    .top-text {
      margin-left: -370px;
      position: absolute;
      top: 290px;
      left: 50%;
      width: 740px;
      text-align: center;
      height: 100px;
      font-size: 36px;
      font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
      font-weight: 300;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
    }
  }
}
@media screen and (max-width: 767px) {
  .title_bj {
    overflow: hidden;
    .customer-top-box {
      .customer-top-title {
        width: 100%;
        font-size: 30px;
        margin-left: -100px;
      }
      .top-text {
        width: 80%;
        font-size: 18px;
        margin-left: -150px;
      }
    }
  }
}
