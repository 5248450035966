.ETMS-box {
  width: 100%;
  background-color: #505ff7;
  height: 540px;
  margin-top: 80px;
  color: #fff;
  position: relative;
  .ETMS-banner-img {
    display: inline-block;
    width: 434px;
    height: 434px;
    position: absolute;
    top: 46px;
    left: 25%;
  }
  .ETMS-banner-right-box {
    width: 367px;
    height: 374px;
    position: absolute;
    top: 74px;
    left: 57%;
    .ETMS-banner-h1 {
      width: 367px;
      height: 85px;
      font-size: 60px;
      font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
      line-height: 84px;
    }
    .ETMS-banner-content {
      width: 310px;
      height: 59px;
      font-size: 18px;
      font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
      line-height: 25px;
      text-align: left;
      margin-top: 8px;
    }
    .banner-button-box {
      margin-top: 47px;
      .banner-button-item {
        text-align: center;
      }
      .bannner-img-describe {
        display: block;
        margin-top: 9px;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 22px;
      }
    }
    .banner-link-btn {
      margin-top: 54px;
      display: inline-block;
      width: 231px;
      height: 42px;
      line-height: 42px;
      background: rgba(255, 255, 255, 1);
      border-radius: 21px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(61, 133, 255, 1);
      padding-left: 20px;
      img {
        margin-left: 14px;
      }
    }
  }
}
.ETMS-box2 {
  width: 100%;
  height: 664px;
  .ETMS-box2-title {
    width: 560px;
    height: 56px;
    font-size: 40px;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: rgba(12, 12, 12, 1);
    line-height: 56px;
    margin: 88px auto 26px;
  }
  .ETMS-product-box {
    width: 100%;
    height: 428px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .ETMS-product-item-box {
      height: 100%;
      width: 320px;
      margin: 20px;
      text-align: center;
      box-shadow: 1px 1px 14px 6px #f5f5f5;
      .ETMS-product-item-title {
        height: 42px;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(12, 12, 12, 1);
        line-height: 42px;
        margin-top: 65px;
        span {
          display: block;
          width: 27px;
          height: 3px;
          background: rgba(77, 77, 77, 1);
          margin: 8px auto;
        }
      }
      img {
        margin-top: 44px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ETMS-box {
    .ETMS-banner-img {
      display: none;
    }
    .ETMS-banner-right-box {
      width: 80%;
      left: 10%;
      .ETMS-banner-h1 {
        font-size: 40px;
        width: 100%;
      }
    }
  }
  .ETMS-box2 {
    height: 190vh;
    .ETMS-box2-title {
      width: 100%;
      font-size: 20px;
      text-align: center;
      margin: 10px 0;
      height: 10vh;
      line-height: 10vh;
    }
    .ETMS-product-box {
      height: 170vh;
      flex-direction: column;
      .ETMS-product-item-box {
        height: 40vh;
        width: 100%;
        margin: 10px 0;
        .ETMS-product-item-title {
          margin-top: 20px;
        }
      }
    }
  }
}
